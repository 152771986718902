import './bootstrap'
import 'normalize.css'
import '../css/app.scss'
import 'vue-awesome-paginate/dist/style.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import VueAwesomePaginate from 'vue-awesome-paginate'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import featureFlags from '@/plugins/featureFlags'
import VueGtag from 'vue-gtag'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'BRFCS'

createInertiaApp({
    title: title => `${title} - ${appName}`,
    resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),

    setup({ el, App, props, plugin }) {
        const brfcsApp = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(VueGtag, { config: { id: import.meta.env.VITE_GTAG_ID } })
            .use(ZiggyVue, Ziggy)
            .use(featureFlags, props.initialPage.props.featureFlags)
            .use(VueAwesomePaginate)
            .mount(el)

        brfcsApp.config.compilerOptions.comments = true

        return brfcsApp
    },
    progress: {
        color: '#4B5563',
    },
})
